import * as React from 'react'
import { generatePath } from 'react-router-dom'
import { format } from 'date-fns'
import { hasValue } from '@digital-magic/ts-common-utils'
import { displayDateFormat, priceCurrency } from '@constants/configuration'
import { routes } from '@constants/routes'
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter'
import classNames from 'classnames'
import { Box, PopperProps } from '@mui/material'
import { Text } from '@controls/Text'
import { PolygonData, housePathId } from '../PriceMap'
import { PolygonLinkStyled, PolygonTooltipStyled } from './PolygonTooltip.styles'
import houseKeyIcon from './house-key-icon.svg'

export const PolygonTooltip = (props: PolygonData): React.ReactElement => {
  const formatCurrency = useCurrencyFormatter()

  const polygonClassName = classNames({ disabled: props.disabled })

  const polygonStyle: React.CSSProperties = {
    clipPath: `url(#${housePathId(props.id)})`
  }

  if (props.disabled) {
    return (
      <PolygonTooltipStyled
        title={
          <Box display="flex" flexDirection="column" alignItems="center">
            <Text size="size-12">{props.address}</Text>
            <Text size="size-12" weight="bold">
              {props.status}
            </Text>
          </Box>
        }
        arrow
        followCursor
      >
        <PolygonLinkStyled as="div" className={polygonClassName} style={polygonStyle} />
      </PolygonTooltipStyled>
    )
  }

  const formattedPrice = formatCurrency(props.price, priceCurrency)

  const tooltipContent = (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Text size="size-12">{props.address}</Text>
      <Text size="size-12" color="grey700" mb={1}>
        {`${props.capacity}m`}
        <sup>2</sup>
      </Text>
      <Text size="size-12" weight="bold">
        {formattedPrice}
      </Text>
      {hasValue(props.handoffAt) && (
        <Box display="flex" alignItems="center" mt={2} bgcolor="white">
          <img src={houseKeyIcon} alt="house key icon" width="32px" height="32px" loading="lazy" />
          <Text size="size-12" weight="bold" mx={1} color="black">
            {format(props.handoffAt, displayDateFormat)}
          </Text>
        </Box>
      )}
    </Box>
  )

  const popperProps: Partial<PopperProps> = {
    placement: 'top',
    style: {
      pointerEvents: 'none'
    }
  }

  const polygonRoute = generatePath(routes.Customer.Booking, { slug: props.slug })

  return (
    <PolygonTooltipStyled title={tooltipContent} arrow followCursor PopperProps={popperProps}>
      <PolygonLinkStyled to={polygonRoute} style={polygonStyle} className={polygonClassName} />
    </PolygonTooltipStyled>
  )
}
