import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { hasValue } from '@digital-magic/ts-common-utils'
import { priceCurrency } from '@constants/configuration'
import { routes } from '@constants/routes'
import { toSquareMeters } from '@utils/formatting-utils'
import { BookingItem, HouseStatus } from '@api/endpoints/buildings/houses'
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter'
import classNames from 'classnames'
import { Typography } from '@mui/material'
import { RouterButton } from '@controls/Button'
import { Table, TableColumn } from '@controls/Table'
import { PriceTableStyled } from './PriceTable.styles'

type PriceTableProps = {
  data: ReadonlyArray<BookingItem>
}

export const PriceTable: React.FC<PriceTableProps> = ({ data }) => {
  const { t, i18n } = useTranslation()

  const formatCurrency = useCurrencyFormatter()

  const isRowAvailable = (row: BookingItem): boolean => {
    return row.status === 'Available'
  }

  const renderSquareMeters =
    <T extends BookingItem>(key: keyof T) =>
    (row: T): React.ReactNode => {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return hasValue(row[key]) ? toSquareMeters(`${row[key]}`) : null
    }

  const renderActionButton = (row: BookingItem): React.ReactNode => {
    const textMap: Record<HouseStatus, string> = {
      Available: t('pages.landing.prices.table.more_info'),
      Sold: t('pages.landing.prices.table.sold'),
      Booked: t('pages.landing.prices.table.booked'),
      Disabled: t('pages.landing.prices.table.disabled')
    }

    return isRowAvailable(row) ? (
      <RouterButton
        color="brown"
        disabled={!isRowAvailable(row)}
        to={generatePath(routes.Customer.Booking, { slug: row.slug })}
      >
        {textMap[row.status]}
      </RouterButton>
    ) : (
      <Typography fontSize={14} align="center" alignSelf="center" width={100}>
        {textMap[row.status]}
      </Typography>
    )
  }

  const renderPrice = (row: BookingItem): React.ReactNode => {
    return row.basePrice && isRowAvailable(row) ? formatCurrency(Number(row.basePrice), priceCurrency) : ''
  }

  const renderAddress = (row: BookingItem): React.ReactNode => {
    return row.apartmentNumber ? `${row.address}-${row.apartmentNumber}` : row.address
  }

  const renderBuildingType = (row: BookingItem): React.ReactNode => {
    return t(`pages.landing.prices.table.house_type.${row.buildingType.code}`)
  }

  const rowClassName = (row: BookingItem): string => {
    return classNames({ disabled: !isRowAvailable(row) })
  }

  const columns: ReadonlyArray<TableColumn<BookingItem>> = React.useMemo(
    () => [
      { key: null, title: t('pages.landing.prices.table.nr'), render: (_, rowIndex) => rowIndex + 1 },
      {
        key: 'address',
        title: t('pages.landing.prices.table.address'),
        render: renderAddress
      },
      {
        key: 'buildingType',
        title: t('pages.landing.prices.table.house_type.title'),
        render: renderBuildingType
      },
      {
        key: 'outdoorSquare',
        title: t('pages.landing.prices.table.oudoor_size'),
        render: renderSquareMeters('outdoorSquare')
      },
      { key: 'floorsCount', title: t('pages.landing.prices.table.floors') },
      {
        key: 'indoorSquare',
        title: t('pages.landing.prices.table.indoor_size'),
        render: renderSquareMeters('indoorSquare')
      },
      {
        key: null,
        title: t('pages.landing.prices.table.storeroom'),
        render: renderSquareMeters('storeRoomSquare')
      },
      {
        key: 'basePrice',
        title: t('pages.landing.prices.table.price'),
        className: 'col-price',
        render: renderPrice
      },
      {
        key: null,
        title: null,
        className: 'col-more-info',
        render: renderActionButton
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  )

  // TODO: This is a temporary solution. We should filter disabled houses on server-side (or at least in parent component)
  const filteredData = React.useMemo(() => data.filter((v) => v.status !== HouseStatus.enum.Disabled), [data])

  return (
    <PriceTableStyled>
      <Table data={filteredData} columns={columns} rowClassName={rowClassName} />
    </PriceTableStyled>
  )
}
