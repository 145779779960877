import * as React from 'react'
import { hasValue } from '@digital-magic/ts-common-utils'
import { BookingItem, BuildingAddress, HouseCode, BookingItemSlug } from '@api/endpoints/buildings/houses'
import { PolygonTooltip } from './PolygonTooltip'
import { PriceMapStyled } from './PriceMap.styles'
import { buildingPathMap } from './PriceMap.utils'
import backgroundImage from './mapBackgound.jpg'

export const housePathId = (id: string): string => `housePath${id}`

type PolygonDataBase = {
  id: string
  path: string
}

type PolygonDataDisabled = PolygonDataBase & {
  disabled: true
  address: BuildingAddress
  status: 'Booked' | 'Sold'
}

type PolygonDataEnabled = PolygonDataBase & {
  disabled: false
  address: BuildingAddress
  capacity: number
  price: number
  slug: BookingItemSlug
  code: HouseCode
  handoffAt?: Date
}

export type PolygonData = PolygonDataEnabled | PolygonDataDisabled

type PriceMapProps = {
  id: string
  data: ReadonlyArray<BookingItem>
}

const enrichPath = (value: string): string => (value.startsWith('M') ? value : `M${value}`)

export const PriceMap: React.FC<PriceMapProps> = ({ id, data }): React.ReactElement => {
  const polygonData: ReadonlyArray<PolygonData> = React.useMemo(() => {
    return Object.entries(buildingPathMap).reduce((result, [address, paths], buildingIndex) => {
      const bookingItems = data.filter((d) => d.address === address)
      //.sort((a, b) => (a.apartmentNumber && b.apartmentNumber ? (a.apartmentNumber > b.apartmentNumber ? 1 : -1) : 0))

      const addressPaths: ReadonlyArray<PolygonData> = paths.map((path, i) => {
        const bookingItem: BookingItem | undefined = bookingItems[i]

        const baseAddressPath: PolygonDataBase = {
          path: enrichPath(path),
          id: `${buildingIndex}-${i}`
        }

        if (bookingItem?.status === 'Available') {
          return {
            ...baseAddressPath,
            disabled: false,
            address: bookingItem.apartmentNumber ? `${address}-${bookingItem.apartmentNumber}` : address,
            // TODO: If we use correct types everywhere - we won't need such conversions
            capacity: Number(bookingItem.indoorSquare) ?? 0,
            price: Number(bookingItem.basePrice),
            slug: bookingItem.slug,
            code: bookingItem.code,
            handoffAt: bookingItem.handoffAt
          }
        } else {
          return {
            ...baseAddressPath,
            disabled: true,
            address: bookingItem?.apartmentNumber ? `${address}-${bookingItem.apartmentNumber}` : address,
            status: bookingItem?.status === 'Sold' ? 'Sold' : 'Booked'
          }
        }
      })

      // eslint-disable-next-line functional/immutable-data
      result.push(...addressPaths.filter(hasValue))

      return result
    }, [] as Array<PolygonData>)
  }, [data])

  return (
    <PriceMapStyled id={id}>
      <img src={backgroundImage} alt="aerial-view" width="100%" loading="lazy" />
      <div className="polygons-container">
        {polygonData.map((h) => (
          <PolygonTooltip key={h.id} {...h} />
        ))}
        <svg viewBox="0 0 1289 725" height="100%" width="100%">
          <defs>
            {polygonData.map((h) => (
              <clipPath
                key={h.id}
                clipPathUnits="objectBoundingBox"
                id={housePathId(h.id)}
                transform="scale(0.00077579519007, 0.001379310344828)"
              >
                <path d={h.path} />
              </clipPath>
            ))}
          </defs>
        </svg>
      </div>
    </PriceMapStyled>
  )
}
